export default {
	init() {
		console.log('page-js loaded');
		jQuery('.cls-1').on('click', function() {
			var calque = jQuery(this);
			var id = calque.attr('id');
			jQuery('.communes__item').removeClass('active');
			jQuery('.' + id).addClass('active');
			$('html,body').animate(
				{
					scrollTop: $('.communes').offset().top - 200,
				},
				'slow'
			);
		});

		$(
			'<h2 class ="content__h2">LES CONSEILLERS COMMUNAUTAIRES</h2>'
		).insertAfter('.trombinoscope__groupe--1');
	},
};
