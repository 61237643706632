// Styles SCSS
import '../sass/app.scss';

// SVG polyfiil
import 'svgxuse';

// LazyLoad
import lazyLoadInit from './lazyload-init';

lazyLoadInit();

import Router from './utils/Router';
import common from './routes/common';
import page from './routes/page';
import single from './routes/single';
import archive from './routes/archive';

// Vanilla
document.addEventListener('DOMContentLoaded', function() {
	const routes = new Router({
		/** All pages */
		common,
		/** page */
		page,
		/** single */
		single,
		/** archive */
		archive,
	});
	routes.loadEvents();
});
