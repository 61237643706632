// Accordions
import Accordion from 'accordion-js';
//Tabs
import { Tabs } from '../utils/tabs';
// Lightbox
import '../utils/fancybox';

export default {
	init() {
		console.log('common-js loaded');

		jQuery('.search-toggle').on('click', function() {
			jQuery(this).toggleClass('active');
			jQuery('.searchform').slideToggle();
			jQuery('.searchform input').focus();
		});

		// Accordions
		if (document.querySelector('.accordion-container') !== null) {
			new Accordion('.accordion-container');
		}
		if (document.querySelector('.accordion-int-container') !== null) {
			new Accordion('.accordion-int-container');
		}

		// Tabs
		if (document.querySelector('.tabs-container') !== null) {
			console.log('in');

			new Tabs({
				elem: 'tabs',
				open: 0,
			});
		}

		// Galery
		$('[data-fancybox="galerie"]').fancybox({
			thumbs: {
				autoStart: true,
			},
		});

		jQuery('.menu-responsive-icon').on('click', function() {
			console.log('click');
			jQuery('.responsive-menu').toggleClass('visible');
		});

		jQuery('.icon-share').on('click', function() {
			jQuery('.icons').toggleClass('visible');
		});
	},
};
